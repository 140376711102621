import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  /* stubbed out css variable colors */
  @font-face {
    font-family: 'roboto-mono';
    src: url("https://use.typekit.net/bef3igs.css")
  }
  @font-face {
    font-family: 'kallisto';
    src: url("https://use.typekit.net/bef3igs.css");
  }
  :root {
    --yellow: #fecb2e;
    --orange: #fc673e;
    --blue: #467190;
    --black: #1e181a;
    --grey: #f8f8f8;
    --white: #fff; 

    --red: #ff6634;
    --lightBlue: #ecf1f4;
    --light: #ffffff;
    --darkGrey: #494949;
    --dark: #000000;
    --lightGrey: #f0f0f0;
    --imGoingToFaint: #fbfbfb;
  }
  html {
    box-sizing: borderbox;
    width: 100%
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    padding: 0;
    margin: 0;
    width: 100%;
  }
  h1, h2, h3, h4, h5 {
    font-family: 'kallisto';
    margin: 0;
    color: var(--black);
  }
  h1, h2 {
    font-size: 40px;
  }
  p {
    font-family: roboto-mono;
    font-size: 18px;
    color: var(--darkGrey);
    letter-spacing: normal;
  }
  a {
    color: var(--orange);
    text-decoration: none;
    box-shadow: none;
    font-family: roboto-mono;
  }
  a:hover {
    color: var(--blue);
    text-decoration: underline !important;
  }
  /* a:active {
    color: var(--white);
    background-color: var(--blue) !important;
  } */
`
export default GlobalStyles
